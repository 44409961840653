import styles from "./ClapButton.module.css";

interface Props {
  count: number;
  black?: boolean;
  addClaps: (clap: number) => void;
}

const ClapButton = ({ count, black, addClaps }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.count}>{count}</div>
      <div
        className={styles.button}
        onClick={(e) => {
          const firework1 = document.createElement("div");
          const firework2 = document.createElement("div");
          const firework3 = document.createElement("div");
          firework1.classList.add(styles.firework);
          firework2.classList.add(styles.firework, styles.alt1);
          firework3.classList.add(styles.firework, styles.alt2);
          e.currentTarget.appendChild(firework1);
          e.currentTarget.appendChild(firework2);
          e.currentTarget.appendChild(firework3);
          setTimeout(() => {
            firework1.remove();
            firework2.remove();
            firework3.remove();
          }, 500);
          addClaps(count + 1);
        }}
      >
        <div className={styles.clap}>
          <img
            src="/icons/clap.svg"
            alt=""
            style={{
              filter: black ? "invert(100%)" : "",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ClapButton;
