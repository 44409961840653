import { useState } from "react";
import { useTranslation } from "react-i18next";
import ImageUploading, { ImageListType } from "react-images-uploading";
import SubmitButton from "../submitButton/SubmitButton";
import TextInput from "../textInput/TextInput";
import styles from "./Uploader.module.css";
import { File } from "../masonry/Masonry";
import { api, getAccessToken } from "../../Requests";

interface Props {
  onUploadDone: (file: File[]) => void;
}

const Uploader = ({ onUploadDone }: Props) => {
  const { t } = useTranslation();
  const [images, setImages] = useState<ImageListType>([]);
  const [firstName, setFirstName] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<string>();

  const onChange = (imageList: ImageListType) => {
    setImages(imageList);
  };

  const onSubmit = async () => {
    if (!images.length) return;
    try {
      setIsLoading(`1 / ${images.length}`);
      const newFiles: File[] = [];
      for (const i of images) {
        const newFile = await api.file.fileControllerUploadFile(
          {
            username: firstName,
            file: i.file,
          },
          { headers: { Authorization: getAccessToken() } }
        );
        if (newFile) {
          newFiles.push({
            name: newFile.data.name,
            size: newFile.data.size.toLowerCase() as File["size"],
            claps: newFile.data.claps,
          });
        }
        setIsLoading((prev) => {
          return `${parseInt(prev || "1") + 1} / ${images.length}`;
        });
      }
      setIsLoading(undefined);
      setError("");
      onUploadDone(newFiles);
    } catch (err: any) {
      if (!err.ok) {
        setError(err.error.message);
      }
      setIsLoading(undefined);
    }
  };

  return (
    <>
      <div className={styles.container}>
        {isLoading && (
          <div className={styles.loading}>
            <div>Loading</div>
            <div className={styles.dotPulse} />
            <div>{isLoading}</div>
          </div>
        )}
        <div className={styles.title}>{t("add_new_pictures")}</div>
        <TextInput
          autoFocus
          text={t("first_name")}
          value={firstName}
          setValue={setFirstName}
          error={error}
        />
        <ImageUploading multiple value={images} onChange={onChange}>
          {({
            imageList,
            onImageUpload,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div>
              <div
                className={styles.draggingArea}
                {...dragProps}
                style={
                  isDragging ? { border: "3px dashed #369775" } : undefined
                }
                onClick={onImageUpload}
              >
                {t("drap_n_drop_here")}
              </div>
              <div className={styles.buttonRow}>
                <SubmitButton
                  onClick={onImageUpload}
                  text={t("add_pictures")}
                />
              </div>
              <div className={styles.imageList}>
                {imageList.map((image, index) => (
                  <div className={styles.imageContainer} key={index}>
                    <img src={image.dataURL} alt="" className={styles.image} />
                    <div className={styles.deleteContainer}>
                      <img
                        className={styles.delete}
                        src="/icons/delete.svg"
                        alt=""
                        onClick={() => {
                          onImageRemove(index);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </ImageUploading>
        <SubmitButton onClick={onSubmit} text={t("send")} />
      </div>
    </>
  );
};

export default Uploader;
