import styles from "./SubmitButton.module.css";

interface Props {
  text: string;
  onClick: () => void;
}

const SubmitButton = ({ text, onClick }: Props) => {
  return (
    <button className={styles.button} type="submit" onClick={onClick}>
      {text}
    </button>
  );
};

export default SubmitButton;
