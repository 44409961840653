import { useState } from "react";
import { useTranslation } from "react-i18next";
import { login } from "../../Requests";
import SubmitButton from "../submitButton/SubmitButton";
import TextInput from "../textInput/TextInput";
import styles from "./Connection.module.css";

interface Props {
  onConnectionDone: () => void;
}

const Connection = ({ onConnectionDone }: Props) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const onSubmit = async () => {
    login(
      password,
      () => {
        setError("");
        onConnectionDone();
      },
      (message) => {
        setError(message);
      }
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t("connect_phrase")}</div>
      <TextInput
        autoFocus
        text={t("password")}
        value={password}
        setValue={setPassword}
        error={error}
        type="password"
        onEnter={onSubmit}
      />
      <SubmitButton text={t("send")} onClick={onSubmit} />
    </div>
  );
};

export default Connection;
