import { Api } from "./swagger/Api";
import { File } from "./components/masonry/Masonry";

// ONLY in dev
// export const host = process.env.REACT_APP_HOSTNAME;

export const getAccessToken = () => {
  return `Bearer ${localStorage.getItem("access_token")}`;
};

export const getRefreshToken = () => {
  return `Bearer ${localStorage.getItem("refresh_token")}`;
};

export const api = new Api({
  // ! Important: ONLY in dev. baseUrl should not be set when builded
  // baseUrl: host,
});

export const fetchImages = async (
  page: number,
  onSuccess: (result: File[]) => void
) => {
  const pictures = (await api.file.fileControllerGetFilesByPage(page)).data;
  const newFiles: File[] =
    pictures?.map((p) => {
      return {
        name: p.name,
        size: p.size.toLowerCase() as File["size"],
        claps: p.claps,
      };
    }) || [];
  onSuccess(newFiles);
};

export const refreshToken = async (
  onSuccess: () => void,
  onError: () => void
) => {
  try {
    const res = await api.auth.authControllerRefreshToken({
      headers: { Authorization: getRefreshToken() },
    });
    const decoded = await res.json();
    localStorage.setItem("access_token", decoded.access_token);
    localStorage.setItem("refresh_token", decoded.refresh_token);
    onSuccess();
  } catch (err: any) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    onError();
  }
};

export const login = async (
  password: string,
  onSuccess: () => void,
  onError: (message: string) => void
) => {
  try {
    const res = await api.auth.authControllerSigninLocal({
      username: "MarcIsTheAdmin",
      password: password,
    });
    const decoded = await res.json();
    localStorage.setItem("access_token", decoded.access_token);
    localStorage.setItem("refresh_token", decoded.refresh_token);
    onSuccess();
  } catch (err: any) {
    if (!err.ok) {
      const error = await err.json();
      onError(error.message);
    }
  }
};
