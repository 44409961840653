export const getErrors = (err: string | string[]) => {
  if (Array.isArray(err)) {
    return err.map((e) => getError(e));
  } else {
    return getError(err);
  }
};

const getError = (err: string) => {
  err = err.toLocaleLowerCase();
  if (err.includes("validation failed (expected type is")) {
    return "wrong_file_type";
  }
  switch (err) {
    case "password should not be empty":
      return "password_should_not_be_empty";
    case "credentials incorrect":
      return "credentials_incorrect";
    case "username should not be empty":
      return "username_should_not_be_empty";
    default:
      return "";
  }
};
