import { ReactElement } from "react";
import styles from "./Modal.module.css";

interface Props {
  children: ReactElement[] | ReactElement;
  isShowing: boolean;
  toggle: () => void;
  black?: boolean;
}

const Modal = ({ children, isShowing, toggle, black }: Props) => {
  return (
    <>
      {isShowing && (
        <div
          className={styles.modal}
          onClick={(e) => {
            if (e.currentTarget === e.target) toggle();
          }}
        >
          <div className={styles.modalContent}>
            <div className={styles.closeButton} onClick={() => toggle()}>
              <img
                style={{
                  filter: black ? "invert(100%)" : "",
                }}
                src="/icons/close.svg"
                alt=""
              />
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
