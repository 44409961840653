import clsx from "clsx";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { api, fetchImages } from "../../Requests";
import ClapButton from "../clapButton/ClapButton";
import AuthenticatedLink from "./AuthenticatedLink";
import styles from "./Masonry.module.css";

export interface File {
  name: string;
  size?: "small" | "tall" | "wide" | "big";
  claps: number;
  type?: "video" | "image";
}

interface Props {
  images: File[];
  setImages: Dispatch<SetStateAction<File[]>>;
  isAuthenticated: boolean;
}

interface Worker {
  timeout: NodeJS.Timeout;
  filename: string;
}

const Masonry = ({ images, setImages, isAuthenticated }: Props) => {
  const [displayImage, setDisplayImage] = useState<File>();
  const workerTimeout = useRef<Worker[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const noImagesLeft = useRef(false);
  const page = useRef(0);
  const isMounted = useRef(false);

  const isVideo = (file: File) => file.type === "video";

  const sendClaps = (filename: string, claps: number) => {
    let currentWorker = workerTimeout.current?.find((e) => {
      return e.filename === filename;
    });
    const newSetTimeout = setTimeout(() => {
      api.file.fileControllerClapFile({ fileName: filename, nbClaps: claps });
      workerTimeout.current?.filter((e) => e.filename === filename);
    }, 500);
    if (!currentWorker) {
      workerTimeout.current.push({
        filename: filename,
        timeout: newSetTimeout,
      });
    } else {
      clearTimeout(currentWorker.timeout);
      currentWorker.timeout = newSetTimeout;
    }
  };

  useEffect(() => {
    if (!isMounted.current) fetchData();
    isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    await fetchImages(page.current, (result) => {
      setImages([...images, ...result]);
      if (result.length === 0) noImagesLeft.current = true;
      page.current += 1;
    });
    setIsLoading(false);
  };

  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node: any) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (element) => {
        if (element[0].isIntersecting && !noImagesLeft.current) {
          fetchData();
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, images]
  );

  const getSize = (index: number) => {
    const range = index % 10;
    switch (range) {
      case 0:
        return "big";
      case 1:
        return "wide";
      case 2:
        return "small";
      case 3:
        return "small";
      case 4:
        return "tall";
      case 5:
        return "wide";
      case 6:
        return "small";
      case 7:
        return "big";
      case 8:
        return "small";
      case 9:
        return "tall";
      default:
        return "small";
    }
  };

  return (
    <>
      <div className={styles.gridWrapper}>
        {images.map((image, index) => {
          return (
            <div
              className={clsx(styles[getSize(index)], styles.wrapper)}
              key={index}
            >
              <div className={styles.container}>
                {isVideo(image) ? (
                  <>
                    <video
                      onClick={() => {
                        setDisplayImage(image);
                      }}
                      className={styles.file}
                      preload="metadata"
                    >
                      <source
                        src={`/file/compressed/${image.name}#t=0.1`}
                        type="video/mp4"
                      />
                    </video>
                    <img
                      className={styles.videoIcon}
                      src="/icons/play.svg"
                      alt=""
                    />
                  </>
                ) : (
                  <img
                    ref={index + 1 === images.length ? lastElementRef : null}
                    className={styles.file}
                    src={`/file/compressed/${image.name}`}
                    alt=""
                    onClick={() => {
                      setDisplayImage(image);
                    }}
                  />
                )}
                {isAuthenticated && (
                  <AuthenticatedLink
                    imagename={image.name}
                    className={styles.button}
                  >
                    <img src="/icons/download.svg" alt="" />
                  </AuthenticatedLink>
                )}
              </div>
              <div className={styles.clapButtonDiv}>
                <ClapButton
                  count={image.claps}
                  addClaps={(clap: number) => {
                    const newImage = { ...image, claps: clap };
                    const newImages = images.map((i) => {
                      if (i.name === image.name) {
                        return newImage;
                      }
                      return i;
                    });
                    setImages(newImages);
                    sendClaps(image.name, clap);
                  }}
                />
              </div>
            </div>
          );
        })}
        {displayImage && (
          <div
            className={styles.modal}
            onClick={(e) => {
              if (e.currentTarget === e.target) setDisplayImage(undefined);
            }}
          >
            <div className={clsx(styles.modalContent)}>
              {isVideo(displayImage) ? (
                <video controls preload="metadata">
                  <source
                    src={`/file/compressed/${displayImage.name}#t=0.1`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img src={`/file/compressed/${displayImage.name}`} alt="" />
              )}
              <div
                className={styles.closeButton}
                onClick={() => {
                  setDisplayImage(undefined);
                }}
              >
                <img
                  src="/icons/close.svg"
                  alt=""
                  style={{
                    filter: isVideo(displayImage) ? "invert(100%)" : "",
                  }}
                />
              </div>
              {isAuthenticated && (
                <AuthenticatedLink
                  imagename={displayImage.name}
                  className={styles.button}
                >
                  <img
                    src="/icons/download.svg"
                    alt=""
                    style={{
                      padding: 10,
                      filter: isVideo(displayImage) ? "invert(100%)" : "",
                    }}
                  />
                </AuthenticatedLink>
              )}
              <div
                className={styles.clapButtonDiv}
                style={{
                  paddingLeft: 15,
                  paddingBottom: 10,
                }}
              >
                <ClapButton
                  count={displayImage.claps}
                  black={isVideo(displayImage)}
                  addClaps={(clap: number) => {
                    const newImage = { ...displayImage, claps: clap };
                    const newImages = images.map((i) => {
                      if (i.name === displayImage.name) {
                        return newImage;
                      }
                      return i;
                    });
                    setImages(newImages);
                    setDisplayImage(newImage);
                    sendClaps(displayImage.name, clap);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {isLoading && (
        <div className={styles.loading}>
          <div>Loading</div>
          <div className={styles.dotPulse} />
        </div>
      )}
    </>
  );
};

export default Masonry;
