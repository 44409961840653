import styles from "./PlusButton.module.css";

interface Props {
  onClick?: () => void;
}

const PlusButton = ({ onClick }: Props) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <img src="./icons/add.svg" alt="" />
    </div>
  );
};

export default PlusButton;
