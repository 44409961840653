import { useTranslation } from "react-i18next";
import { getErrors } from "../../utils/Errors";
import { nanoid } from "nanoid";
import styles from "./TextInput.module.css";

interface Props {
  text: string;
  value: string;
  setValue: (value: string) => void;
  error?: string;
  type?: "text" | "password";
  onEnter?: () => void;
  autoFocus?: boolean;
}

const TextInput = ({
  text,
  value,
  setValue,
  onEnter,
  error,
  type = "text",
  autoFocus = false,
}: Props) => {
  const { t } = useTranslation();
  const id = nanoid();

  return (
    <div className={styles.textInputWrapper}>
      <div className={styles.textInput}>
        <label htmlFor={id}>{text}</label>
        <input
          autoFocus={autoFocus}
          onKeyPress={
            onEnter
              ? (e) => {
                  if (e.key === "Enter") {
                    onEnter();
                  }
                }
              : undefined
          }
          type={type}
          name={text}
          id={id}
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
      </div>
      {error && <div className={styles.error}>{t(getErrors(error))}</div>}
    </div>
  );
};

export default TextInput;
