import { createRef, ReactElement } from "react";
import { api, getAccessToken } from "../../Requests";

interface Props {
  imagename: string;
  children: ReactElement;
  className: string;
}

const AuthenticatedLink = ({ imagename, children, className }: Props) => {
  const link = createRef<HTMLAnchorElement>();

  const handleAction = async () => {
    if (link.current?.href) {
      return;
    }
    const res = await api.file.fileControllerGetOriginalFile(imagename, {
      headers: { Authorization: getAccessToken() },
    });
    const blob = await res.blob();
    const href = window.URL.createObjectURL(blob);
    if (link.current) {
      link.current.download = imagename;
      link.current.href = href;
      link.current.click();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a role="button" ref={link} onClick={handleAction} className={className}>
      {children}
    </a>
  );
};
export default AuthenticatedLink;
