import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  fr: {
    translation: {
      wedding: "Mariage",
      password_should_not_be_empty: "Mot de passe est requis",
      credentials_incorrect: "Mot de passe incorrect",
      connect_phrase:
        "Se connecter permet d'ajouter et de télécharger des images.",
      send: "Envoyer!",
      password: "Mot de passe",
      first_name: "Votre prénom",
      add_pictures: "Ajouter des photos",
      add_new_pictures: "Ajoutez de nouvelles photos!",
      delete_all: "Tout supprimer",
      username_should_not_be_empty: "Votre prénom est requis",
      wrong_file_type: "Ce type de fichier n'est pas pris en charge",
      drap_n_drop_here: "Glissez-déposez vos photos ici !",
    },
  },
  es: {
    translation: {
      wedding: "Boda",
      password_should_not_be_empty: "Contraseña requerida",
      credentials_incorrect: "Contraseña incorrecta",
      connect_phrase: "Iniciar sesión le permite agregar y descargar imágenes.",
      send: "¡Enviar!",
      password: "Contraseña",
      first_name: "Su nombre",
      add_pictures: "Añadir fotos",
      add_new_pictures: "¡Añadir nuevas fotos!",
      delete_all: "Eliminar todo",
      username_should_not_be_empty: "Su nombre es requerido",
      wrong_file_type: "Este tipo de archivo no es compatible",
      drap_n_drop_here: "¡Arrastra y suelta tus fotos aquí!",
    },
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: "fr",
});

export default i18n;
