import { ReactElement } from "react";
import styles from "./Fab.module.css";

interface Props {
  children: ReactElement;
  onClick: () => void;
}

const Fab = ({ children, onClick }: Props) => {
  return (
    <div onClick={onClick} className={styles.fab}>
      {children}
    </div>
  );
};

export default Fab;
