import styles from "./App.module.css";
import { useTranslation } from "react-i18next";
import PlusButton from "./components/plusButton/PlusButton";
import Masonry, { File } from "./components/masonry/Masonry";
import Uploader from "./components/uploader/Uploader";
import Modale from "./components/modale/Modal";
import Fab from "./components/fab/Fab";
import useModal from "./components/modale/useModal";
import Connection from "./components/connection/Connection";
import { useEffect, useRef, useState } from "react";
import { refreshToken } from "./Requests";
import clsx from "clsx";

function App() {
  const [images, setImages] = useState<File[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isMounted = useRef(false);
  const { t, i18n } = useTranslation();
  const { isShowing: isShowingUpload, toggle: toggleUpload } = useModal();
  const { isShowing: isShowingPassword, toggle: togglePassword } = useModal();

  useEffect(() => {
    if (!isMounted.current) {
      refreshToken(
        () => {
          setIsAuthenticated(true);
        },
        () => {
          setIsAuthenticated(false);
        }
      );
      isMounted.current = true;
    }
  }, []);

  return (
    <div className={styles.app}>
      <header className={styles.header}>
        <div className={styles.hero}>
          <img src="hero.JPG" alt="" />
        </div>
        {isAuthenticated && (
          <a className={styles.button} href="/hero.JPG" download>
            <img src="/icons/download.svg" alt="My Happy SVG" />
          </a>
        )}
        <h1
          className={clsx(
            styles.title,
            isAuthenticated ? styles.titleNoMargin : ""
          )}
        >
          Bibiana y Marc
        </h1>
        <h2 className={styles.subTitle}>{t("wedding")}</h2>
        {!isAuthenticated && isMounted.current && (
          <div className={styles.login}>
            <img
              src="/icons/account.svg"
              alt=""
              onClick={() => {
                togglePassword();
              }}
            />
          </div>
        )}
        <div className={styles.lang}>
          <img
            src="/icons/colombia.png"
            alt=""
            onClick={() => {
              i18n.changeLanguage("es");
            }}
          />
          <img
            src="/icons/france.png"
            alt=""
            onClick={() => {
              i18n.changeLanguage("fr");
            }}
          />
        </div>
      </header>
      <main>
        <Modale isShowing={isShowingUpload} toggle={toggleUpload} black>
          <Uploader
            onUploadDone={(newFiles) => {
              toggleUpload();
              setImages([...images, ...newFiles]);
            }}
          />
        </Modale>
        <Modale isShowing={isShowingPassword} toggle={togglePassword} black>
          <Connection
            onConnectionDone={() => {
              setIsAuthenticated(true);
              togglePassword();
            }}
          />
        </Modale>
        <Masonry
          images={images}
          setImages={setImages}
          isAuthenticated={isAuthenticated}
        />
        {isAuthenticated && (
          <Fab onClick={() => toggleUpload()}>
            <PlusButton />
          </Fab>
        )}
      </main>
      <footer>
        <div className={styles.copyright}>
          Copyright © {new Date().getFullYear()} Marc Leonetti
        </div>
        <a
          className={styles.hint}
          href="mailto: marc2_leonetti@hotmail.com?subject=Wedding website"
        >
          Contact
        </a>
      </footer>
    </div>
  );
}

export default App;
